<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'" style="min-height: 80vh;">
    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <img src="@/assets/images/confirmation.png" width="250" />
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.status_type == 'delete' ? 'menghapus' : 'mengeluarkan' }} outlet dari Franchise
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false"
            >
              Ke List
              <v-icon color="#d31145" small class="ml-1"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog v-model="dialog.detail" persistent width="450">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 white--text"
          style="background-color: #d31145;"
        >
          Detail Pegawai
        </v-card-title>
        <v-card-text class="pt-5">
          <v-img
            v-if="checkFormatImg(detail.user_image_url)"
            class="mx-auto"
            :aspect-ratio="1 / 1"
            :width="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            :height="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            style="position: relative; z-index: 1;"
            contain
            v-show="detail.user_image_url"
            :src="detail.user_image_url"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height justify-center ma-0" align="center">
                <v-progress-circular indeterminate color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            v-else
            class="mx-auto circle"
            :aspect-ratio="1 / 1"
            :width="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            :height="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            style="position: relative; z-index: 1; border-radius: 50px; border: 8px solid #ecf0f1"
            contain
            v-show="detail.user_image_url"
            :src="detail.user_image_url"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height justify-center ma-0" align="center">
                <v-progress-circular indeterminate color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="mt-10">
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Nama
              </p>
              {{ detail.user_name }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Username
              </p>
              {{ detail.user_username }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Email
              </p>
              {{ detail.user_email }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Nomor Handphone
              </p>
              {{ detail.user_mobilephone }}
            </v-list-item-title>
          </div>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.detail = false"
          >
            <v-icon color="#d31145" small class="mr-1"
              >mdi-close-circle</v-icon
            >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG PENGELUARAN STORE ==== -->
    <v-dialog v-model="dialog.status" scrollable persistent width="500">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.status_type == "kick_out" ?  "Keluarkan Outlet dari Franchise" : "Tolak pengajuan penarikan komisi" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialog.status = false;">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.status_type == "kick_out" ? "Pengeluaran Outlet dari Franchise" : "Tolak pengajuan penarikan komisi" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <div class="subtitle-2 font-weight-bold">
            Nama Outlet
          </div>
          <v-text-field
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Nama Outlet"
            readonly
            color="#d31145"
            v-model="detail.store_name">
          </v-text-field>
          <div class="subtitle-2 font-weight-bold">
            Alasan pengeluaran
          </div>
          <v-textarea
            solo
            flat
            dense
            background-color="#EEEEEE"
            placeholder="Tuliskan alasan pengeluaran outlet dari franchise"
            color="#d31145"
            v-model="form.reason">
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.status_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DATA PEGAWAI ==== -->
    <v-row class="justify-space-between mt-0">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <div class="text--secondary mb-2" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
          Data Outlet
        </div>
        <div class="d-flex align-center">
          <Export
            class="mb-3 ml-2 mr-2"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="'franchise/store/export_excel'"
            :disabled="$store.state.loading"
            :title="'Data-Outlet'"
            ref="base-export"
            v-on:onClickExport="responseExport"
          />
          <!-- <v-btn
            depressed
            v-if="$vuetify.breakpoint.name !== 'sm'"
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize mb-3"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
          </v-btn> -->
        </div>
        <!-- <div v-if="$vuetify.breakpoint.name === 'sm'">
          <v-btn
            depressed
            :width="$vuetify.breakpoint.name === 'xs' ? '100%' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize mb-3"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            Filter
          </v-btn>
        </div> -->
      </v-col>
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <v-text-field
          outlined
          dense
          :hide-details="true"
          v-model="searching"
          placeholder="Cari Data . . ."
          clearable
          color="#d31145"
          @click:clear="searching = ''"
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider style="display: none;" class="mt-4" v-if="$vuetify.breakpoint.name !== 'xs'"></v-divider>

    <!-- ==== LIST DATA OUTLET ====  -->
    <div :class="$vuetify.breakpoint.name !== 'xs' ? 'my-5' : 'mb-5'" style="min-height: calc(100vh - 415px)">
      <v-skeleton-loader
        :loading="process.run"
        transition="scale-transition"
        :tile="false"
        type="table-tbody"
      >
        <base-table
          customHeight="calc(100vh - 350px)"
          :isExternalFilterButton="true"
          :isFilterOnHeader="true"
          v-show="displayBaseTable"
          ref="base-table"
          :filterHeader="filterHeader_storeList"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          retrieveDataURL="franchise/store"
          v-on:onRetrieveDataEnd="responseDataEnd"
        >
          <template v-slot:[`item.aksi_table`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  :disabled="item.status == 'accept'"
                  min-width="30"
                  class="text-capitalize red--text pa-0 mr-1"
                  @click="actions(item, 'kick_out')">
                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-store-remove</v-icon>
                </v-btn>
              </template>
              <span>Hapus dari Franchise</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{item}">
            <v-list-item class="px-0">

              <v-list-item-content>
                <v-list-item-title class="subtitle-2">
                  {{ item.store_name }} <strong class="red--text">{{ item.is_trial_bool == true ? '(Trial)' : '' }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-html="item.category_name" class="caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:[`item.owner_name`]="{item}">
            <v-list-item class="pl-0">
              <v-list-item-content>
                <v-list-item-title v-html="item.owner_name" class="subtitle-2"></v-list-item-title>
                <v-list-item-subtitle v-html="item.owner_email" class="caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:[`item.owner_mobilephone`]="{item}">
            <a :href="`tel:+${item.owner_mobilephone}`" class="text-decoration-underline">{{ item.owner_mobilephone }}</a>
          </template>

          <template v-slot:[`item.join_datetime`]="{item}">
            {{ item.join_datetime | datetime }}
          </template>
          
          <template v-slot:[`item.is_active_bool`]="{ item }">
            <div
              :class="
                item.is_active_bool
                  ? 'caption text-capitalize font-weight-bold text-center teal--text'
                  : 'caption text-capitalize font-weight-bold text-center red--text text--darken-2'
              "
            >
              {{ item.is_active_bool ? 'Aktif' : 'Tidak aktif' }}
            </div>
          </template>

          <template v-slot:filterTitle>
            Filter Data Pegawai
          </template>
        </base-table>
      </v-skeleton-loader>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-model="contentEmpty" v-show="emptyData" />
    </div>

  </div>
</template>
<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Empty from "@/components/Empty";
import Pagination from "@/components/Pagination";
import Export from "@/components/Export";
export default {
  data() {
    return {
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      headers: [
        {
          text: "Aksi",
          sortable: false,
          width: "50",
          value: "aksi_table",
          align: "center",
        },
        {
          text: "Tanggal Bergabung",
          sortable: true,
          width: "150",
          value: "join_datetime",
          align: "center",
        },
        {
          text: "Nama Outlet",
          sortable: true,
          width: "150",
          value: "name",
          align: "start",
        },
        {
          text: "Nama Pemilik",
          sortable: true,
          width: "150",
          value: "owner_name",
          align: "start",
        },
        {
          text: "No. Telepon",
          sortable: true,
          width: "150",
          value: "owner_mobilephone",
          align: "start",
        },
        {
          text: "Status",
          width: "50",
          align: "center",
          sortable: true,
          value: "is_active_bool",
        },
      ],

      filterHeader_storeList: [
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "text",
          column: "name",
          valueDefault: "",
          label: "Berdasarkan Nama Outlet",
        },
        {
          type: "select",
          column: "user_is_active",
          valueDefault: "",
          label: "Pilih Status",
          items: ["waiting", "accept"],
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      color: "",
      response: "",
      searching: "",
      contentEmpty: "",
      detail: {},
      pagination: {
        data: {},
        page: "",
      },
      selected: {
        pagination: 0,
      },
      limit: 10,
      page: 1,
      file: null,
      avatar: null,
      showPage: false,
      form: {
        store_id: "",
        reason: "",
        email_array: []
      },
      search_email: "",
      process: {
        form: false,
        run: false,
      },
      dialog: {
        status: false,
        status_type: "delete",
        success: false,
        detail: false,
        form: false,
        form_type: "",
        reset: false,
        resetSuccess: false,
      },
      list: [],
      group: [],
      response: "",
      feedback: "",
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  watch: {
    file: {
      handler: function() {
        // this.form.image_url = this.avatar.url
        // this.addImage = this.avatar.add
      },
      deep: true,
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      this.$refs["base-table"].searchVal = newVal;
      this.$refs["base-table"].retrieveData();
      // this.initialize();
    }, 500),
    // 'selected.pagination': function(val) {
    //   this.initialize(val+1)
    // }
  },
  created() {
    // this.initialize();
    this.fetchGroup();
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    /**
     * HANDLE EXPORT
     */
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END HANDLE EXPORT
     */

    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */

    checkFormatImg(url) {
      if (url) {
        return this.$options.filters.isPng(url);
      }
    },

    actions (item, type) {
      switch (type) {
        case 'add':
          this.dialog.form = true
          this.dialog.form_type = "add"
          break;
        case 'kick_out':
          this.response = '' 
          this.detail = item
          this.form = {
            store_id: item.store_id,
            reason: ""
          }
          this.dialog.status = true;
          this.dialog.status_type = 'kick_out';
          break;
      }
    },

    async toDetail(item) {
      this.detail = item;
      this.dialog.detail = true;
    },
    async save(type) {
      this.response = "";
      this.process.form = true;
      let url = ""
      let data = {}
      if (type == 'kick_out') {
        url = `franchise/store/kick`
        data = {
          store_id: this.form.store_id,
          reason: this.form.reason
        }
      }
      await post(url, {
        data
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.dialog.status = false;
            this.dialog.success = true;
            this.process.form = false;

            this.selected.pagination = 1;
            this.page = 1;
            this.pagination.page = 1;

            this.$refs["base-table"].retrieveData();
          } else {
            this.process.form = false;
            this.response = res.msg;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;
            this.dialog.status = false;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
  },
};
</script>

<style>
.line:hover {
  border-left: 6px solid #d31145;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s;
}
.background-cols {
  background-color: #ffca8e61;
  border: 3px solid #ffc58461;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.pagination {
  position: fixed;
  bottom: 65px;
}
.pagination-center .v-slide-group__content {
  justify-content: center !important;
}
</style>
